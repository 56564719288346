import InnerPageContainer from "./InnerPageContainer";

export default function Page() {
  return (
    <InnerPageContainer title="Privacy Policy">
      <p className="mt-8">
        Gaia Studio is committed to protecting your privacy. This Privacy Policy
        explains how we collect, use, disclose, and safeguard your personal
        information when you visit our website or use our design services
        powered by AI (the design service).
        <br />
        <br />
      </p>
    </InnerPageContainer>
  );
}
