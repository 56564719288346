import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Landing from "../pages/Landing";
import HomePage from "../pages/HomePage";
import Logout from "../pages/Logout";
import Layout from "../layouts/Layout";
import Sessions from "../pages/Sessions";
import Packages from "../pages/Packages";
import Profile from "../pages/Profile";
import AboutUs from "../pages/AboutUs";
import OurStudio from "../pages/OurStudio";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Faqs from "../pages/Faqs";
import TermsAndCondition from "../pages/TermsAndCondition";
import ContactUs from "../pages/ContactUs";
import Home from "../pages/Home";
import SessionDetail from "../pages/SessionDetail";
import SessionSchedules from "../pages/SessionSchedules";
import AllSchedules from "../pages/AllSchedules";
import FinishPayment from "../pages/FinishPayment";
import { useEffect, useState } from "react";

const Routes = () => {
  const { token } = useAuth();
  const [router, setRouter] = useState(null);

  const routesForPublic = [
    {
      children: [
        {
          element: <Layout />, // Wrap the component in Layout
          children: [
            {
              path: "/service",
              element: <div>Service Page</div>,
            },
            {
              path: "/about-us",
              element: <AboutUs />,
            },
            {
              path: "/our-studio",
              element: <OurStudio />,
            },
            {
              path: "/privacy-policy",
              element: <PrivacyPolicy />,
            },
            {
              path: "/faqs",
              element: <Faqs />,
            },
            {
              path: "/terms-and-conditions",
              element: <TermsAndCondition />,
            },
            {
              path: "/contact-us",
              element: <ContactUs />,
            },
            {
              path: "/start-designing",
              element: <Home />,
            },
          ],
        },
      ],
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      children: [
        {
          element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
          children: [
            {
              path: "",
              element: <HomePage />,
            },
            {
              path: "/",
              element: <HomePage />,
            },
            {
              path: "/profile",
              element: <Profile />,
            },
            {
              path: "logout",
              element: <Logout />,
            },
            {
              path: "/schedules",
              element: <AllSchedules />,
            },
            {
              path: "/packages",
              element: <Packages />,
            },
          ],
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      // path: "/",
      children: [
        {
          path: "",
          element: <Layout />, // Wrap the component in Layout
          children: [
            {
              path: "/",
              element: localStorage.getItem("token") ? (
                <HomePage />
              ) : (
                <Landing />
              ),
            },
            {
              path: "finish-payment",
              element: <FinishPayment />,
            },
            {
              path: "/sessions",
              element: <Sessions />,
            },
            {
              path: "/schedules",
              element: <AllSchedules />,
            },
            {
              path: "/sessions/:id",
              element: <SessionDetail />,
            },
            {
              path: "/sessions/schedules/:id",
              element: <SessionSchedules />,
            },
            {
              path: "/packages",
              element: <Packages />,
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    let combinedRoutes = routesForPublic;

    if (localStorage.getItem("token")) {
      combinedRoutes = [
        ...combinedRoutes,
        ...routesForNotAuthenticatedOnly,
        ...routesForAuthenticatedOnly,
      ];
    } else {
      combinedRoutes = [...combinedRoutes, ...routesForNotAuthenticatedOnly];
    }

    setRouter(createBrowserRouter(combinedRoutes));
  }, [localStorage.getItem("token"), token]);

  if (!router) {
    return <></>;
  }

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
