import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../store/modalSlice";
import { setCredits, setLoggedIn } from "../store/userSlice";
import { SIGN_UP_IMAGES, MODAL_BODY_TYPES } from "../utils/globalConstantUtil";
import { postData, setAuthorizationToken } from "../utils/init";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

function SignInModalBody({ closeModal, extraObject }) {
  const INITIAL_REGISTER_OBJ = {
    otp: "",
    emailId: "",
    password: "",
  };
  const navigate = useNavigate();
  const { isSignIn } = extraObject;
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(false);
    setIsOtpSent(false);
    setErrorMessage("");
    setLoginObj({ otp: "", emailId: "", password: "" });
  }, [isSignIn]);

  const [loading, setLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginObj, setLoginObj] = useState(INITIAL_REGISTER_OBJ);
  const { setToken } = useAuth();

  const openSignUp = () => {
    dispatch(
      openModal({
        title: "",
        size: "lg",
        bodyType: MODAL_BODY_TYPES.SIGN_UP_MODAL,
        extraObject: { isSignIn: false },
      })
    );
  };

  const openSignIn = () => {
    dispatch(
      openModal({
        title: "",
        size: "lg",
        bodyType: MODAL_BODY_TYPES.SIGN_IN_MODAL,
        extraObject: { isSignIn: true },
      })
    );
  };

  // useEffect(() => {
  //    if(loginObj.otp.length > 2){
  //             console.log(loginObj)
  //         submitVerificationCode()
  //    }
  // }, [loginObj.otp])

  const submitVerificationCode = async (e) => {
    setErrorMessage("");
    if (loginObj.emailId.trim() === "")
      return setErrorMessage("Email/Username is Required!");
    if (loginObj.password.trim() === "")
      return setErrorMessage("Password is Required!");
    // if (loginObj.otp.trim() === "")
    //   return setErrorMessage("Verification Code is Required!");
    // else if (
    //   !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(loginObj.emailId.trim())
    // ) {
    //   return setErrorMessage("Username/Email is Wrong!");
    // }
    else {
      setLoading(true);
      // let response = await axios.post('/user/verifyMailOTP', loginObj)
      // if(response.data.success){
      if (1) {
        // Call API to check user credentials and save token in localstorage
        const res = await postData("/login", {
          username: loginObj.emailId,
          password: loginObj.password,
        });

        if (res?.token) {
          localStorage.setItem("token", res?.token);
          setAuthorizationToken(res?.token);
          localStorage.setItem("user", JSON.stringify(res.user));
          dispatch(setLoggedIn(true));
          setToken(res?.token);
          // dispatch(setToken(res?.token));
          closeModal();
          const redirectPath = localStorage.getItem("redirectPath") || "/";
          localStorage.removeItem("redirectPath");
          if (redirectPath) {
            navigate(redirectPath);
          } else {
            navigate("/");
          }
        }

        setLoading(false);

        return setErrorMessage(res?.data?.message);
        //Analytics Tracking
      } else {
        // setErrorMessage(response.data.message);
      }
      setLoading(false);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (loading) return 1;
    // if (isOtpSent) {
    //   submitVerificationCode();
    // } else {
    //   sendMailOtp();
    // }
    submitVerificationCode();
  };

  const sendMailOtp = async (e) => {
    setErrorMessage("");
    if (loginObj.emailId.trim() === "")
      return setErrorMessage("Email/Username is Required!");
    if (loginObj.password.trim() === "")
      return setErrorMessage("Password is Required!");
    // else if (
    //   !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(loginObj.emailId.trim())
    // ) {
    //   return setErrorMessage("Email Id is Wrong!");
    // }
    else {
      setLoading(true);
      // Call API to check user credentials and save token in localstorage
      // let response = await axios.post('/user/sendMailOTP', loginObj)
      setLoading(false);
      setIsOtpSent(true);

      // if(response.data.success){
      //     // setLoginObj({...loginObj, otp : response.data.payload.otp+""})
      //     setIsOtpSent(true)
      // }else{
      //     setErrorMessage(response.data.message)
      // }
    }
  };

  const updateFormValue = ({ updateType, value }) => {
    setErrorMessage("");
    setLoginObj({ ...loginObj, [updateType]: value });
  };

  return (
    <div className=" flex items-center rounded-xl">
      <div className="grid grid-cols-1 md:grid-cols-2 w-full ">
        <div className="text-center rounded-xl bg-base-100 ">
          <div className="mt-10 md:mb-0 mb-10 inline-block">
            <span className="font-bold text-2xl">
              <img
                className="mask inline-block mr-2 mask-circle w-10"
                src="/android-chrome-192x192.png"
              />{" "}
              Gaia Studio
            </span>
            <div className="carousel   mt-6 w-full">
              {SIGN_UP_IMAGES.map((img, k) => {
                return (
                  <div
                    id={"slide" + (k + 1)}
                    key={k}
                    className="carousel-item relative w-full"
                  >
                    <div className="w-full h-96 max-sm:h-fit">
                      <img
                        src="/background.jpeg"
                        alt="Shoes"
                        className="rounded-xl"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="md:p-10 pb-12">
          <form onSubmit={(e) => submitForm(e)}>
            <div className="mb-4">
              {!isOtpSent && (
                <p className="text-center md:mt-0 mt-6 text-xl mb-4 font-semibold">
                  Sign In
                </p>
              )}
              {isOtpSent && (
                <>
                  <p className="text-center text-lg   md:mt-0 mt-6   font-semibold">
                    Enter verification code received on {loginObj.emailId}
                  </p>
                  <p className="text-center text-slate-500 mt-2 text-sm">
                    Didn&apos;t receive mail? Check spam folder
                  </p>
                </>
              )}
              {!isOtpSent && (
                <>
                  <div className={`form-control w-full mt-8`}>
                    <label className="label">
                      <span
                        className={
                          "label-text text-base-content text-xs text-slate-600 "
                        }
                      >
                        {"Enter your Email/Username"}
                      </span>
                    </label>
                    <input
                      type={"text"}
                      value={loginObj.emailId}
                      placeholder={"Ex- username@gmail.com"}
                      onChange={(e) =>
                        updateFormValue({
                          updateType: "emailId",
                          value: e.target.value,
                        })
                      }
                      className="input input-bordered input-primary w-full "
                    />
                  </div>
                  <div className={`form-control w-full mt-8`}>
                    <label className="label">
                      <span
                        className={
                          "label-text text-base-content text-xs text-slate-600 "
                        }
                      >
                        {"Enter your Password"}
                      </span>
                    </label>
                    <input
                      type={"password"}
                      value={loginObj.password}
                      placeholder={""}
                      onChange={(e) =>
                        updateFormValue({
                          updateType: "password",
                          value: e.target.value,
                        })
                      }
                      className="input  input-bordered input-primary w-full "
                    />
                  </div>
                </>
              )}

              {isOtpSent && (
                <div className={`form-control w-full mt-8`}>
                  <label className="label">
                    <span
                      className={
                        "label-text text-base-content text-xs text-slate-600"
                      }
                    >
                      {"Verification Code"}
                    </span>
                  </label>
                  <input
                    type={"otp"}
                    value={loginObj.otp}
                    placeholder={"Ex- 123456"}
                    onChange={(e) =>
                      updateFormValue({
                        updateType: "otp",
                        value: e.target.value,
                      })
                    }
                    className="input  input-bordered input-primary w-full "
                  />
                </div>
              )}
            </div>

            <div className={`${isSignIn ? "mt-6" : "mt-6"} text-rose-500`}>
              {errorMessage}
            </div>

            {/* {!isSignIn && <div className="badge badge-warning float-right ml-2 text-xs normal-case">Get 5 Credits FREE on Sign Up</div>} */}
            {/* <div className="badge badge-secondary float-right ml-2 normal-case">Get 5 Credits FREE</div> */}
            <button
              type="submit"
              className={"btn mt-2 normal-case w-full btn-primary text-white  "}
            >
              {loading && <span className="loading loading-spinner"></span>}
              {isOtpSent ? `Verify` : `Sign In`}
            </button>

            {isSignIn ? (
              <div className="text-center mt-4">
                {`Don't have an account yet?`}
                <div onClick={() => openSignUp()} className="ml-2 inline-block">
                  <span className="  inline-block  hover:text-primary hover:underline hover:cursor-pointer transition duration-200">
                    Sign Up
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-center mt-4">
                Already have an account?{" "}
                <div onClick={() => openSignIn()} className="inline-block">
                  <span className="  inline-block  hover:text-primary hover:underline hover:cursor-pointer transition duration-200">
                    Sign In
                  </span>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignInModalBody;
