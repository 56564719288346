import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PresentationChartBarIcon from "@heroicons/react/24/outline/PresentationChartBarIcon";
import Bars3BottomLeftIcon from "@heroicons/react/24/outline/Bars3BottomLeftIcon";
import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import WeekView3 from "../components/WeekView3";
import { fetchData } from "../utils/init";

function SessionDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(false);

  const load = async () => {
    try {
      setLoading(true);
      const responseSession = await fetchData(`/session/${id}`);

      setSession(responseSession);
      setLoading(false);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return (
      <span className="loading loading-spinner w-[70px] mx-auto flex justify-center min-h-screen"></span>
    );
  }

  return (
    <div className="m-24 max-sm:m-5">
      <div className="grid gap-10 grid-cols-2 max-sm:grid-cols-1">
        <div>
          <div className="flex gap-2 items-center">
            <div>
              <button
                className="px-1 py-1 btn btn-square btn-sm"
                onClick={() => {
                  navigate(`/sessions`);
                }}
              >
                <ChevronLeftIcon className="h-4 w-4" />
              </button>
            </div>
            <div className="text-sm breadcrumbs">
              <ul>
                <li>
                  <a href="/sessions">Sessions</a>
                </li>
                <li>{session.name}</li>
              </ul>
            </div>
          </div>

          <div className="flex justify-between items-center mt-4 mb-10 max-sm:flex-col gap-5">
            <h1 className="text-4xl  font-bold">{session.name}</h1>

            <div className="card-actions ">
              <button
                className="btn btn-primary w-full"
                onClick={() =>
                  navigate(`/sessions/schedules/${session.id_session}`, {
                    replace: false,
                  })
                }
              >
                See The Schedule
              </button>
            </div>
          </div>

          <div className="flex items-start gap-2 mb-10">
            <div className="w-[5%]">
              <PresentationChartBarIcon className="w-5" />
            </div>
            <div>
              <p className="font-semibold ">Difficulty Level</p>
              <p className=" capitalize">
                {session.difficulty === "opentoall"
                  ? "Open To All"
                  : session.difficulty}
              </p>
            </div>
          </div>

          <div className="flex items-start gap-2 mb-10">
            <div className="w-[5%]">
              <Bars3BottomLeftIcon className="w-5" />
            </div>
            <div>
              <p className="font-semibold ">About this session</p>
              <p className=" capitalize">{session.description}</p>
            </div>
          </div>
        </div>
        <div>
          <div className="card w-full bg-base-100 shadow-xl rounded-xl">
            <figure>
              <img
                src={`${
                  process.env.REACT_APP_ENV === "production"
                    ? process.env.REACT_APP_BASE_URL_PROD
                    : process.env.REACT_APP_BASE_URL_SANDBOX
                }/public/uploads/assets/${session?.photo}`}
                alt="Not Found"
              />
            </figure>
          </div>
        </div>
      </div>

      <div className="flex my-10 w-full p-10 shadow-lg max-sm:flex-col">
        <div className="w-[30%] max-sm:w-full font-bold">
          Terms And Condition
        </div>
        <div>
          <ul>
            {session.termscondition?.split(",")?.map((item) => {
              if (item) {
                return <li className="text-lg font-base">- {item}</li>;
              }
            })}
          </ul>
        </div>
      </div>

      {/* <div>
        <div className="pt-10 pb-5 font-extrabold text-2xl text-center border-b-4 border-black border-t-0 border-x-0 w-max mx-auto">
          SCHEDULES
        </div>

        <div>
          <WeekView3 schedulesData={schedules} sessions={sessions} />
        </div>
      </div> */}
    </div>
  );
}

export default SessionDetail;
