import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { postData } from "../utils/init";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../store/userSlice";
import Layout from "../layouts/Layout";

export const ProtectedRoute = () => {
  const { token } = useAuth();
  const dispatch = useDispatch();

  const location = useLocation();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  // If authenticated, render the child routes
  return (
    <div className="">
      <Layout>
        <Outlet />
      </Layout>
    </div>
  );
};
