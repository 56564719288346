import AuthProvider from "./provider/authProvider";
import Routes from "./routes";
import { initializeApp } from "./utils/init";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

function App() {
  initializeApp();

  // <script type="text/javascript" src="https://app.stg.midtrans.com/snap/snap.js" data-client-key="SET_YOUR_CLIENT_KEY_HERE"></script>
  useEffect(() => {
    const script = document.createElement("script");
    const midtransClientKey =
      process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_MIDTRANS_CLIENT_KEY_PRODUCTION
        : process.env.REACT_APP_MIDTRANS_CLIENT_KEY_SANDBOX;

    const midtransClientKeyUrl =
      process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_MIDTRANS_CLIENT_KEY_PRODUCTION_URL
        : process.env.REACT_APP_MIDTRANS_CLIENT_KEY_SANDBOX_URL;

    console.log("midtransClientKey", midtransClientKey);
    console.log("midtransClientKeyUrl", midtransClientKeyUrl);

    script.src = midtransClientKeyUrl;
    script.setAttribute("data-client-key", midtransClientKey);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <AuthProvider>
      <ToastContainer
        position="top-right"
        // autoClose={5000}
        // hideProgressBar={false}
        // newestOnTop={false}
        // closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
        // theme="light"
        // transition: Bounce,
      />
      <Routes />
    </AuthProvider>
  );
}

export default App;
