module.exports = Object.freeze({
  MODAL_BODY_TYPES: {
    USER_DETAIL: "USER_DETAIL",
    SIGN_IN_MODAL: "SIGN_IN_MODAL",
    SIGN_UP_MODAL: "SIGN_UP_MODAL",
    PRICING_MODAL: "PRICING_MODAL",
    DEFAULT: "",
  },
  LEFT_DRAWER_TYPES: {
    PROCESS_SELECT_STYLE: "PROCESS_SELECT_STYLE",
  },
  SIGN_UP_IMAGES: [
    {
      title: "Sinup to see magic",
      imageUrl:
        "https://plus.unsplash.com/premium_photo-1681319553238-9860299dfb0f?auto=format&fit=crop&q=80&w=2831&ixlib=rb-4.0.3",
    },
    {
      title: "Elevate elegance with your signature style.",
      imageUrl:
        "https://plus.unsplash.com/premium_photo-1681319553238-9860299dfb0f?auto=format&fit=crop&q=80&w=2831&ixlib=rb-4.0.3",
    },
    {
      title: "Design your office ",
      imageUrl:
        "https://plus.unsplash.com/premium_photo-1681319553238-9860299dfb0f?auto=format&fit=crop&q=80&w=2831&ixlib=rb-4.0.3",
    },
    {
      title: "Elegance with your signature style.",
      imageUrl:
        "https://plus.unsplash.com/premium_photo-1681319553238-9860299dfb0f?auto=format&fit=crop&q=80&w=2831&ixlib=rb-4.0.3",
    },
    {
      title: "Website in 20 minutes",
      imageUrl:
        "https://plus.unsplash.com/premium_photo-1681319553238-9860299dfb0f?auto=format&fit=crop&q=80&w=2831&ixlib=rb-4.0.3",
    },
  ],
});
