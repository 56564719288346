import { fetchData, postData } from "../utils/init";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon";

function FinishPayment() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderId = params.get("order_id");
  // const statusCode = params.get("status_code");
  const transactionStatus = params.get("transaction_status");
  const navigate = useNavigate();

  const load = async () => {
    const subscription = await fetchData("/subscriptions/" + orderId);
    if (subscription) {
      await postData("/updateStatusTransaction", {
        id_subscription: subscription.id_subscription,
        payment_status: transactionStatus,
      });

      if (transactionStatus === "pending") {
        window.snap.pay(subscription.snap_token, {
          onSuccess: async function (result) {},
          onPending: async function (result) {},
          onError: function (result) {},
          onClose: function () {},
        });
      }
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div className="min-h-[90vh] p-10">
      {transactionStatus === "capture" || transactionStatus === "settlement" ? (
        <div className="w-full flex justify-center items-center my-20">
          <div className="card bg-base-100 w-96 shadow-2xl">
            <div className="card-body">
              <div className="flex justify-center items-center mb-10">
                <CheckCircleIcon className="w-24" />
              </div>
              <h2 className="card-title">Transaksi Berhasil</h2>
              <p>Terima kasih, pembayaran Anda telah diterima.</p>
              <div className="card-actions w-full mt-10">
                <Link className="btn btn-primary w-full" to="/">
                  Ke Halaman Utama
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {transactionStatus === "deny" || transactionStatus === "cancel" ? (
            <div className="w-full flex justify-center items-center my-20">
              <div className="card bg-base-100 w-96 shadow-2xl">
                <div className="card-body">
                  <div className="flex justify-center items-center mb-10">
                    <XCircleIcon className="w-24" />
                  </div>
                  <h2 className="card-title">Transaksi Gagal</h2>
                  <p>Transaksi anda gagal mohon untuk dicoba lagi.</p>
                  <div className="card-actions w-full mt-10">
                    <Link className="btn btn-primary w-full" to="/packages">
                      Ke Halaman packages
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {transactionStatus === "pending" ? (
                <>{}</>
              ) : (
                <>
                  {transactionStatus === "expire" ? (
                    <div className="w-full flex justify-center items-center my-20">
                      <div className="card bg-base-100 w-96 shadow-2xl">
                        <div className="card-body">
                          <div className="flex justify-center items-center mb-10">
                            <XCircleIcon className="w-24" />
                          </div>
                          <h2 className="card-title">Transaksi Expired</h2>
                          <p>Transaksi anda sudah expired</p>
                          <div className="card-actions w-full mt-10">
                            <Link
                              className="btn btn-primary w-full"
                              to="/packages"
                            >
                              Ke Halaman packages
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>{navigate("/packages")}</>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default FinishPayment;
