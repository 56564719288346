import { Link, useNavigate } from "react-router-dom";

function Navlinks({ buttonDrawer }) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  return (
    <>
      <li className="mr-2">
        <Link
          onClick={(e) => {
            e.preventDefault();
            buttonDrawer?.current?.click();
            navigate("/");
          }}
          to="/"
        >
          Home
        </Link>
      </li>
      <li className="mr-2">
        <Link
          onClick={(e) => {
            e.preventDefault();
            buttonDrawer?.current?.click();
            navigate("/sessions");
          }}
          to="/sessions"
        >
          Sessions
        </Link>
      </li>
      <li className="mr-2">
        <Link
          onClick={(e) => {
            e.preventDefault();
            buttonDrawer?.current?.click();
            navigate("/packages");
          }}
          to="/packages"
        >
          Packages
        </Link>
      </li>
      <li className="mr-2">
        <Link
          onClick={(e) => {
            e.preventDefault();
            buttonDrawer?.current?.click();
            navigate("/schedules");
          }}
          to="/schedules"
        >
          Schedules
        </Link>
      </li>
      <li className="mr-2">
        <Link
          onClick={(e) => {
            e.preventDefault();
            buttonDrawer?.current?.click();
            navigate("/our-studio");
          }}
          to="/our-studio"
        >
          Our Studio
        </Link>
      </li>
      {!token ? (
        <>
          <li className="mr-2">
            <Link
              onClick={(e) => {
                e.preventDefault();
                buttonDrawer?.current?.click();
                navigate("/contact-us");
              }}
              to="/contact-us"
            >
              Contact Us
            </Link>
          </li>
          <li className="mr-2">
            <Link
              onClick={(e) => {
                e.preventDefault();
                buttonDrawer?.current?.click();
                navigate("/faqs");
              }}
              to="/faqs"
            >
              FAQs
            </Link>
          </li>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Navlinks;
