/* eslint-disable jsx-a11y/anchor-is-valid */
import { fetchData, postData } from "../utils/init";
import React, { useEffect, useState, useRef } from "react";
import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import CircleStackIcon from "@heroicons/react/24/outline/CircleStackIcon";
import dayjs from "dayjs";
import ListView from "../components/ListView";
import { toast } from "react-toastify";

function HomePage() {
  const calendarRef = useRef();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const [myPackages, setMyPackages] = useState([]);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const load = async () => {
    const data = await fetchData(`/getSubscriptionByIdMember/${user.username}`);
    if (data.length) {
      setSelectedSubscriptionId(data[0].id_subscription);
      setSelectedSubscription(data[0]);
    }

    setMyPackages(data ? data : []);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    loadSubscription();
  }, [selectedSubscriptionId]);

  // function getWeekDate(date) {
  //   const inputDate = new Date(date);
  //   const day = inputDate.getDay(); // 0 (Sunday) to 6 (Saturday)

  //   // Calculate difference to Sunday
  //   const diffToSunday = -day;
  //   // Calculate difference to Saturday
  //   const diffToSaturday = 6 - day;

  //   const sunday = new Date(inputDate);
  //   sunday.setDate(inputDate.getDate() + diffToSunday);

  //   const saturday = new Date(inputDate);
  //   saturday.setDate(inputDate.getDate() + diffToSaturday);

  //   return {
  //     start: dayjs(sunday).format("YYYY-MM-DDTHH:mm:ss"),
  //     end: dayjs(saturday).format("YYYY-MM-DDTHH:mm:ss"),
  //   };
  // }

  const loadSubscription = async (start, end) => {
    try {
      setLoading(true);

      const response = await fetchData(
        `/getActiveSessionBySubscription/${selectedSubscriptionId}`
      );

      // console.clear();
      console.log(response, "response");

      const days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];

      let data = [];
      if (response) {
        response.forEach((res) => {
          const date = new Date(res.date);

          if (res.id_member !== user.username) {
            return;
          }

          const findIsDayExist = data.find(
            (item) => item.day === days[date.getDay()]
          );

          if (findIsDayExist) {
            data = data.map((item) => {
              if (item.day === days[date.getDay()]) {
                return {
                  ...item,
                  schedules: [...item.schedules, res],
                };
              }

              return item;
            });
          } else {
            data.push({
              day: days[date.getDay()],
              date: date,
              schedules: [res],
            });
          }
        });
      }

      setEvents(response ? data : []);
      setLoading(false);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      // setLoading(false);
    }
  };

  return (
    <div className="px-10 py-3 min-h-screen max-sm:px-0">
      <div className="pt-5 pb-5 font-extrabold text-2xl text-center border-b-4 border-black border-t-0 border-x-0 w-max mx-auto">
        My Sessions
      </div>
      <div className="w-full flex items-center justify-center mb-3 my-5">
        <div className="flex items-center justify-center gap-3 ">
          <div className="text-xl font-semibold leading-none ml-5">
            {`${dayjs(selectedSubscription?.start_subscription).format(
              "MMMM D"
            )} - ${dayjs(selectedSubscription?.end_subscription).format(
              "MMMM D, YYYY"
            )}`}
          </div>
        </div>
      </div>

      <div className="w-full grid grid-cols-3 max-sm:grid-cols-1 my-10">
        <div className="bg-white border ">
          <div className="min-h-[60vh] max-h-[60vh] max-sm:min-h-[100px] max-sm:max-h-[100px] overflow-scroll">
            <ul className="menu max-sm:carousel max-sm:max-w-md max-sm:flex max-sm:max-h-[100px] menu-lg bg-white w-full p-0 [&_li>*]:rounded-none">
              {myPackages.map((item) => {
                return (
                  <li
                    key={item.id_subscription}
                    className={`border ${
                      item.id_subscription === selectedSubscriptionId
                        ? "bg-gray-100"
                        : ""
                    }`}
                  >
                    <a
                      className="flex flex-col gap-3 p-2"
                      onClick={() => {
                        if (item.transaction_status === "pending") {
                          toast.error("Please complete your payment", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                          });
                          return;
                        }
                        setSelectedSubscriptionId(item.id_subscription);
                        setSelectedSubscription(item);
                      }}
                    >
                      <div className="w-full text-start">
                        <div className="text-xs">{item.nama_produk}</div>
                        <div>
                          {item.nama_paket} {item.times} Session
                        </div>
                      </div>
                      <div className="w-full flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <CircleStackIcon className="w-4" />
                          <span
                            className={`text-sm font-semibold ${
                              item.times == item.active_claim
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {item.active_claim}/{item.times}
                          </span>
                        </div>
                        <div className="flex gap-3">
                          {item.transaction_status == "pending" ? (
                            <div
                              className={"tooltip"}
                              data-tip={
                                "Go to your profile to complete your payment"
                              }
                            >
                              <div class="badge badge-error text-white">
                                Pending Payment
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div
                            className={
                              item.subscription_group_ids ? "tooltip" : ""
                            }
                            data-tip={
                              item.subscription_group_ids
                                ? `Group ${item.subscription_group_ids}`
                                : ""
                            }
                          >
                            <div class="badge">
                              {item.subscription_group_ids ? "Group" : "Single"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div
          className={`col-span-2 min-h-[60vh] max-h-[60vh] overflow-scroll ${
            events.length === 0 ? "flex justify-center" : ""
          }`}
        >
          <ListView
            data={events}
            actionLoadDate={loadSubscription}
            selectedSubscription={selectedSubscription}
          />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
