import InnerPageContainer from "./InnerPageContainer";
const faqsData = [
  {
    question: "Apa itu Pilates?",
    answer:
      "Pilates adalah bentuk latihan dengan dampak rendah yang berfokus pada penguatan otot, memperbaiki postur, dan meningkatkan fleksibilitas melalui gerakan yang terkontrol.",
  },
  {
    question: "Siapa yang bisa melakukan Pilates?",
    answer:
      "Pilates cocok untuk semua orang dari segala usia dan tingkat kebugaran. Latihan ini dapat dimodifikasi untuk pemula atau mereka yang memiliki masalah kesehatan tertentu, serta dapat diperkuat untuk praktisi yang lebih berpengalaman.",
  },
  {
    question: "Seberapa sering saya harus melakukan Pilates?",
    answer:
      "Untuk hasil terbaik, disarankan untuk melakukan Pilates 2-3 kali per minggu. Konsistensi adalah kunci untuk melihat peningkatan dalam kekuatan, fleksibilitas, dan postur.",
  },
  {
    question: "Apakah saya memerlukan peralatan khusus?",
    answer:
      "Pilates dapat dilakukan di atas matras dengan hanya menggunakan berat tubuh Anda, tetapi beberapa latihan mungkin menggunakan alat seperti resistance band, bola stabilitas, atau reformer Pilates. Sebagian besar kelas akan menyediakan peralatan yang diperlukan.",
  },
  {
    question: "Apa yang harus saya kenakan ke kelas Pilates?",

    answer:
      "Kenakan pakaian yang nyaman dan pas yang memungkinkan gerakan bebas. Hindari pakaian yang terlalu longgar, karena bisa mengganggu gerakan Anda. Kaos kaki dengan grip direkomendasikan untuk stabilitas.",
  },
  {
    question: "Apakah Pilates bisa membantu menurunkan berat badan?",
    answer:
      "Pilates dapat berkontribusi pada penurunan berat badan jika dikombinasikan dengan diet seimbang dan bentuk latihan lainnya. Latihan ini terutama bertujuan untuk mengencangkan otot dan memperbaiki postur, tetapi juga membakar kalori.",
  },
  {
    question: "Apakah Pilates baik untuk nyeri punggung?",
    answer:
      "Ya, Pilates sering direkomendasikan untuk individu dengan nyeri punggung karena memperkuat otot inti yang mendukung tulang belakang, memperbaiki postur, dan mendorong peregangan yang lembut.",
  },
  {
    question: "Apa perbedaan antara Pilates dan Yoga?",
    answer:
      "Meskipun Pilates dan yoga sama-sama fokus pada koneksi antara pikiran dan tubuh, Pilates menekankan kekuatan inti dan gerakan yang terkontrol, seringkali menggunakan peralatan khusus. Yoga, di sisi lain, mencakup berbagai praktik yang mungkin fokus pada fleksibilitas, pernapasan, dan meditasi.",
  },
  {
    question: "Bisakah saya melakukan Pilates selama kehamilan?",
    answer:
      "Pilates dapat bermanfaat selama kehamilan, terutama jika dimodifikasi untuk menyesuaikan perubahan dalam tubuh Anda. Penting untuk berkonsultasi dengan penyedia layanan kesehatan dan instruktur Pilates yang berpengalaman dengan Pilates prenatal.",
  },
];

export default function Page() {
  return (
    <InnerPageContainer title="FAQs">
      {faqsData.map((f, k) => {
        return (
          <div
            className="collapse bg-white md:mx-12  my-8 rounded-lg"
            key={k}
            style={{
              boxShadow: "rgba(0, 0, 0, 0.07) 0px 4px 16px",
            }}
          >
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              {f.question}
            </div>
            <div className="collapse-content">
              <p className="text-start">{f.answer}</p>
            </div>
          </div>
        );
      })}
    </InnerPageContainer>
  );
}
