import React from "react";
import { useEffect, useState } from "react";
import CalendarView from "../components/CalendarView";
import { fetchData } from "../utils/init";

function AllSchedules() {
  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [sessions, setSessions] = useState([]);

  function getRandomHexColor() {
    let hexColor = "#";
    const hexDigits = "0123456789ABCDEF";

    for (let i = 0; i < 3; i++) {
      let value;
      do {
        value = Math.floor(Math.random() * 256); // Generate a random value between 0 and 255
      } while (value > 200 || value < 50); // Avoid light colors and too dark colors

      hexColor += hexDigits[Math.floor(value / 16)]; // Convert to hex
      hexColor += hexDigits[value % 16];
    }

    return hexColor;
  }

  const load = async () => {
    try {
      setLoading(true);

      const responseSessions = await fetchData(`/getSessionsAllActive`);

      const response = await fetchData(`/schedules`);

      let sessionTempData = responseSessions ? responseSessions : [];

      setSchedules(response ? response : []);

      // get session with color in the local storage
      let sessionsColors = localStorage.getItem("sessionsColors")
        ? JSON.parse(localStorage.getItem("sessionsColors"))
        : null;

      if (sessionsColors) {
        sessionTempData = sessionTempData?.map((item) => {
          return {
            ...item,
            color: sessionsColors[item.id_session],
          };
        });
      } else {
        sessionsColors = {};

        sessionTempData = sessionTempData?.map((item) => {
          let color = getRandomHexColor();
          sessionsColors[item.id_session] = color;
          return {
            ...item,
            color: color,
          };
        });

        localStorage.setItem("sessionsColors", JSON.stringify(sessionsColors));
      }

      console.log(sessionsColors, "sessionsColors");
      // let data = [];
      // sessionTempData.forEach((temp) => {
      //   console.log(temp, "temp");
      //   let find = data.find(
      //     (sessionI) => sessionI.id_product == temp.id_product
      //   );

      //   if (find) {
      //     let findIndex = data.findIndex(
      //       (sessionI) => sessionI.id_product == temp.id_product
      //     );

      //     data[findIndex].items = [...data[findIndex]?.items, temp];
      //   } else {
      //     data.push({
      //       id_product: temp.id_product,
      //       nama: temp.nama_produk,
      //       items: [temp],
      //     });
      //   }
      // });
      setSessions(sessionTempData);
      setLoading(false);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return (
      <span className="loading loading-spinner w-[70px] mx-auto flex justify-center min-h-screen"></span>
    );
  }

  return (
    <div>
      <div className="m-5">
        <CalendarView
          schedules={schedules}
          sessions={sessions}
          setSchedules={setSchedules}
        />
      </div>
    </div>
  );
}

export default AllSchedules;
