import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Navlinks from "../components/Navlinks";
import NavProfileLinks from "../components/NavProfileLinks";
import LeftSidebar from "../components/LeftSidebar";
import ModalLayout from "../components/ModalLayout";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Layout() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [showDrawer, setShowDrawer] = useState(false);
  const buttonDrawer = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="drawer">
        <input
          id="my-drawer-3"
          type="checkbox"
          className="drawer-toggle"
          defaultChecked={showDrawer}
          ref={buttonDrawer}
        />
        <div className="drawer-content flex flex-col">
          <div className="flex flex-col ">
            <Navbar setShowDrawer={setShowDrawer} />

            <div
              className="overflow-y-auto"
              style={
                location.pathname == "/" && !token
                  ? {
                      backgroundImage: "url(/background.jpeg)",
                      backgroundAttachment: "fixed",
                      position: "relative",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }
                  : {}
              }
            >
              <main>
                <Outlet />
              </main>
              <Footer />
            </div>
          </div>
        </div>

        <div className="drawer-side max-sm:z-[49]">
          <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 h-full bg-base-100 pt-20 ">
            <Navlinks buttonDrawer={buttonDrawer} />
            <NavProfileLinks />
          </ul>
        </div>
      </div>
      <LeftSidebar />
      <ModalLayout />
    </>
  );
}

export default Layout;
