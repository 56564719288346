import { useEffect } from "react";
import { MODAL_BODY_TYPES } from "../utils/globalConstantUtil";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../store/modalSlice";
import SignInModalBody from "../components/SignInModalBody";
import SignUpModalBody from "../components/SignUpModalBody";

function ModalLayout() {
  const { isOpen, bodyType, size, extraObject, title } = useSelector(
    (state) => state.modal
  );
  const dispatch = useDispatch();

  const close = (e) => {
    dispatch(closeModal(e));
  };

  return (
    <>
      {/* The button to open modal */}

      {/* Put this part before </body> tag */}
      <div className={`modal ${isOpen ? "modal-open" : ""}`}>
        <div
          className={`modal-box  ${size === "lg" ? "max-w-5xl" : ""} ${
            extraObject.darkbg ? "bg-base-100" : ""
          }`}
        >
          <button
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={() => close()}
          >
            ✕
          </button>
          <h3 className="font-semibold text-xl pb-6 text-center">{title}</h3>

          {/* Loading modal body according to different modal type */}
          {
            {
              [MODAL_BODY_TYPES.SIGN_IN_MODAL]: (
                <SignInModalBody closeModal={close} extraObject={extraObject} />
              ),
              [MODAL_BODY_TYPES.SIGN_UP_MODAL]: (
                <SignUpModalBody closeModal={close} extraObject={extraObject} />
              ),
              [MODAL_BODY_TYPES.DEFAULT]: <div></div>,
            }[bodyType]
          }
        </div>
      </div>
    </>
  );
}

export default ModalLayout;
